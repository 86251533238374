import React, { useContext, useEffect, useState } from "react";
import { details } from "../../api";
import axios from "axios";
import { Context, actions } from "../../context";
import {
  Button,
  Card,
  Grid,
  Loading,
  Stepper,
  Text,
} from "@sede-x/shell-ds-react-framework";
import "./DhcSurvey.css";
import {
  Prompt,
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom";
import DhcQuestions from "./DhcQuestions";
import orderBy from "lodash.orderby";
import DhcSummary from "./DhcSummary";

const steps = ["Content Assurance", "Software Engineering", "Summary"];

const DhcSurvey = () => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const loginUser = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};
  const [activeStep, setActiveStep] = useState(0);
  const [contentSurveyResult, setContentSurveyResult] = useState([]);
  const [softwareSurveyResult, setSoftwareSurveyResult] = useState([]);
  const [count, setCount] = useState({});
  const [graphResult, setGraphResult] = useState({
    contentSurveyResult: 0,
    softwareSurveyResult: 0,
    overallSurveyResult: 0,
  });

  const [loader, setLoader] = useState(false);

  const {
    BASE_URL,
    GET_QUESTIONS_URL,
    GET_AGREEMENTLEVEL_URL,
    POST_SURVEYDETAILS_URL,
    POST_PROJECTDETAILS_URL,
    SAVE_EDT_SURVEY,
  } = details;

  const {
    banner,
    selectedFlag,
    selectedProject,
    surveyQuestions,
    agreementLevels,
    surveyAllData,
    RAMNeeded,
    newProjectID,
    DHCID,
    EDT,
    EDTSurvey,
  } = state;
  const formData = state.formData;
  const contentFinalSurveyResult = state.contentFinalSurveyResult;
  const softwareFinalSurveyResult = state.softwareFinalSurveyResult;
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    if (!formSubmit) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formSubmit]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "You have unsaved changes, are you sure you want to leave?";
  };

  useEffect(() => {
    if (!formSubmit) {
      const unblock = history.block((location, action) => {
        if (
          action === "PUSH" &&
          location.pathname !== "/review" &&
          location.pathname !== "/riskAssessment"
        ) {
          return window.confirm(
            "You have unsaved changes, are you sure you want to leave this page?"
          );
        }
        return true;
      });
      return () => unblock();
    }
  }, [history, formSubmit]);

  // useEffect(() => {
  //   console.log("Survey Page - Form Data", formData);
  // }, []);
  // useEffect(() => {
  //   console.log("State", state);
  // }, []);

  useEffect(() => {
    Promise.all([loadAgreementLevels(), loadSurveyQuestions()]);
  }, [selectedProject]);

  const loadAgreementLevels = async () => {
    try {
      let url = `${BASE_URL}${GET_AGREEMENTLEVEL_URL}`;
      let response = await axios.get(url); //"loadAgreementLevels",
      // let response1 = await api.getCalls("loadAgreementLevels", url);
      let { data: { recordset: recordSet = [] } = {}, error } = response;
      // console.log("Agreement-", response);
      error
        ? setBanner(true, "error", error)
        : dispatch({
            type: actions.SET_AgreementLevels,
            payload: recordSet,
          });
    } catch (error) {
      const errorCode = error.response ? error.response.status : 500;
      console.log(`Agreement Levels not Loaded-`, error.message);
      history.push("/errorPage", { error, errorCode });
    }
  };

  const loadSurveyQuestions = async () => {
    try {
      let ComplexityID;
      if (selectedFlag === "existingProject") {
        ComplexityID = selectedProject.ComplexityID;
      } else {
        ComplexityID = formData.ComplexityID;
      }
      let url = `${BASE_URL}${GET_QUESTIONS_URL}`;
      // let response = await api.getCalls("loadSurveyQuestions", url, {
      //   ComplexityID,
      // });
      let response = await axios.get(url, { params: { ComplexityID } }); //"loadSurveyQuestions",
      let { data: { recordset: recordSet = [] } = {}, error } = response;
      // console.log("Complexity Call-", response);

      if (error) setBanner(true, "error", error);
      else {
        if (selectedFlag === "existingProject") {
          dispatch({
            type: actions.SET_SurveyQuestions,
            payload: recordSet.filter((d) => d.Areas === selectedProject.Areas),
          });
        } else {
          dispatch({
            type: actions.SET_SurveyQuestions,
            payload: recordSet.filter((d) => d.Areas === formData.Areas),
          });
        }
      }
    } catch (error) {
      const errorCode = error.response ? error.response.status : 500;
      console.log(`DHC Survey Questions not Loaded-`, error.message);
      history.push("/errorPage", { error, errorCode });
    }
  };

  const resetTakeSurvey = () => {
    dispatch({
      type: actions.SET_TakingSurvey,
      payload: !state.takingSurvey,
    });
  };

  const GetFeedbackCount = (type, num) => {
    switch (type) {
      case "CA":
        setCount({ ...count, CA: num });
        break;
      default:
        setCount({ ...count, SE: num });
        break;
    }
  };

  const setBanner = (show, type = "error", error) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message: error,
      },
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DhcQuestions
            Heading={"CA"}
            setCount={GetFeedbackCount}
            surveyQuestions={orderBy(surveyQuestions, "QuestionID")}
            {...{
              agreementLevels,
              contentSurveyResult,
              setContentSurveyResult,
            }}
          />
        );
      case 1:
        return (
          <DhcQuestions
            Heading={"SE"}
            setCount={GetFeedbackCount}
            surveyQuestions={orderBy(surveyQuestions, "QuestionID")}
            {...{
              agreementLevels,
              softwareSurveyResult,
              setSoftwareSurveyResult,
            }}
          />
        );
      case 2:
        return (
          <DhcSummary
            listofAgreementLevels={agreementLevels}
            contentSurveyResult={contentSurveyResult}
            softwareSurveyResult={softwareSurveyResult}
            selectedProject={selectedProject}
            graphResult={graphResult}
            setGraphResult={setGraphResult}
            formData={formData}
            selectedFlag={selectedFlag}
          />
        );
    }
  };

  const getfeedback = (activeStep) => {
    if (activeStep === 0) {
      return (
        surveyQuestions.filter((item) => item.SurveyID !== 1).length !==
        count.CA
      );
    } else {
      return (
        surveyQuestions.filter((item) => item.SurveyID !== 2).length !==
        count.SE
      );
    }
  };

  const handleNext = async () => {
    // console.log("State", state);
    let { ProjectID } = selectedProject;
    if (activeStep === 0 && ProjectID && ProjectID !== null) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 2) {
      setLoader(!loader);
      // let url = `${BASE_URL}${POST_SURVEYDETAILS_URL}`;
      let { UserID } = loginUser;
      // let response = await api.postCalls("submitSurvey", url, {
      //   UserID,
      //   selectedProject,
      //   contentSurveyResult,
      //   softwareSurveyResult,
      //   graphResult,
      // }); //"submitSurvey",

      // console.log("Content Survey Result", contentSurveyResult);
      // console.log("Software Survey Result", softwareSurveyResult);
      dispatch({
        type: actions.SET_CONTENT_SURVEY_RESULT,
        payload: contentSurveyResult,
      });
      dispatch({
        type: actions.SET_SOFTWARE_SURVEY_RESULT,
        payload: softwareSurveyResult,
      });
      const params = {
        UserID,
        contentSurveyResult,
        softwareSurveyResult,
        graphResult,
      };
      dispatch({
        type: actions.SET_SURVEY_ALL_DATA,
        payload: params,
      });
      if (RAMNeeded) {
        await new Promise((resolve) => {
          setFormSubmit(true);
          resolve();
        });
        history.push("/riskAssessment");
      } else {
        try {
          let ProjectID, ProjectAreas;
          // First API call
          if (selectedFlag === "newProject") {
            ProjectAreas = formData.Areas;
            let url1 = `${BASE_URL}${POST_PROJECTDETAILS_URL}`;
            let response1 = await axios.post(url1, formData);
            let { data: { recordset: recordSet = [] } = {}, error: error1 } =
              response1;
            ProjectID = recordSet[0].ProjectID;

            dispatch({
              type: actions.SET_PROJECT_ID,
              payload: ProjectID,
            });

            // console.log("Project ID- ", ProjectID);
            // console.log("Response1", response1);
            if (error1) {
              console.log("Error in Create Project", response1.error);
              setBanner(true, "error", error1);
              throw new Error(error1); // Stop execution if there's an error
            } else {
              console.log("Project Created Successfully", recordSet[0]);
            }
          } else if (selectedFlag === "existingProject") {
            ProjectID = selectedProject.ProjectID;
            ProjectAreas = selectedProject.Areas;
            dispatch({
              type: actions.SET_PROJECT_ID,
              payload: ProjectID,
            });
          }
          // console.log("Project Area: ", ProjectAreas);
          let DHCIDreturnValue = "",
            EDTID;
          // Second API call
          if (ProjectAreas === "Emerging Digital Technologies") {
            // console.log("Inside", ProjectAreas, ProjectID);
            const url2 = `${BASE_URL}${SAVE_EDT_SURVEY}`;
            const params = { ...EDTSurvey, projectId: ProjectID };
            const response2 = await axios.post(url2, params); //'saveEdt'
            // console.log(response2);
            EDTID = response2.data.edtId;
            dispatch({
              type: actions.SET_EDT,
              payload: response2.data,
            });
          } else {
            let url2 = `${BASE_URL}${POST_SURVEYDETAILS_URL}`;
            const params1 = { ...params, ProjectID };
            console.log("Params - ", params1);
            let response2 = await axios.post(url2, params1);
            let { error: error2, data: { recordset = [], returnValue } = {} } =
              response2;
            DHCIDreturnValue = returnValue;
            console.log("Response2 DS", response2);
            console.log("returnValue", returnValue);
            // Check for errors in the second API call
            if (response2.error) {
              console.log("Error in Survey Details", response2.error);
              setBanner(true, "error", response2.error);
              throw new Error(response2.error); // Stop execution if there's an error
            } else {
              dispatch({
                type: actions.SET_DHCID,
                payload: returnValue,
              });
              dispatch({
                type: actions.SET_selectedMenuIndex,
                payload: {
                  index: 1,
                },
              });
              await new Promise((resolve) => {
                setFormSubmit(true);
                resolve();
              });
              history.push("/review");
            }
          }
        } catch (error) {
          console.log("Error in Survey Details", error);
          console.log(`Project Not Created-`, error.message);
          const errorCode = error.response ? error.response.status : 500;
          history.push("/errorPage", { error, errorCode });
        }
      }
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (formData) {
      console.log("Content SURVEY CONTENT: ", state.contentFinalSurveyResult);
      console.log("SOFTWARE SURVEY CONTENT: ", state.softwareFinalSurveyResult);
    }
  }, [state.contentFinalSurveyResult, state.softwareFinalSurveyResult]);

  const handleBack = () => {
    if (activeStep === 0) {
      history.push("/home");
      dispatch({
        type: actions.SET_selectedMenuIndex,
        payload: {
          index: 5,
        },
      });
      dispatch({
        type: actions.SET_SelectedFlag,
        payload: {
          selectedFlag: "searchProject",
        },
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getNextText = (activeStep) => {
    switch (activeStep) {
      case 0:
        return "NEXT";
      case 1:
        return "NEXT";
      case 2:
        return RAMNeeded ? "RAM SURVEY" : "SUBMIT";
      default:
        return "SAVE AND CONTINUE";
    }
  };

  return (
    <>
      <Prompt
        when={activeStep < steps.length - 1}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {activeStep === steps.length ? (
        <Redirect to="/survey" />
      ) : (
        <div className="survey-container">
          <Card className="stepper-container">
            <Stepper current={activeStep}>
              {steps.map((label, index) => (
                <Stepper.Step
                  className="survey-stepper"
                  key={label}
                  order={index + 1}
                  title={label}
                  type="dot"
                />
              ))}
            </Stepper>
          </Card>
          <br />
          <br />
          {activeStep === steps.length ? (
            resetTakeSurvey()
          ) : (
            <div className="survey-body-container">
              {activeStep === 0 && (
                <>
                  <Card className="summary-card">
                    <div className="project-summary">
                      <Grid container columns="20% auto">
                        <Grid children>
                          <Text bold>Project Name:</Text>
                        </Grid>
                        <Grid children>
                          {selectedFlag === "existingProject" ? (
                            <Text bold>{selectedProject.ProjectName}</Text>
                          ) : (
                            <Text bold>{formData.Name}</Text>
                          )}
                        </Grid>
                        {(selectedProject.FunnelProjectID ||
                          formData.FunnelProjectID) && (
                          <>
                            <Grid children>
                              <Text bold>Project Opportunity ID:</Text>
                            </Grid>
                            <Grid children>
                              {selectedFlag === "existingProject" ? (
                                <Text>{selectedProject.FunnelProjectID}</Text>
                              ) : (
                                <Text>{formData.FunnelProjectID}</Text>
                              )}
                            </Grid>
                          </>
                        )}

                        <Grid children>
                          <Text bold> Domain:</Text>
                        </Grid>
                        <Grid children>
                          {selectedFlag === "existingProject" ? (
                            <Text>{selectedProject.Areas}</Text>
                          ) : (
                            <Text>{formData.Areas}</Text>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Card>
                  <br />
                  <br />
                </>
              )}

              {getStepContent(activeStep)}
              {surveyQuestions.length === 0 && agreementLevels.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    marginBottom: "50px",
                  }}
                >
                  <Loading size="large" type="default" />
                </div>
              )}
              <div className="survey-btn-container">
                <Button
                  size="medium"
                  variant="filled"
                  sentiment="default"
                  onClick={handleBack}
                  style={{ marginRight: "36px" }}
                >
                  BACK
                </Button>
                <Button
                  size="medium"
                  variant="filled"
                  sentiment="default"
                  disabled={getfeedback(activeStep)}
                  onClick={handleNext}
                  loading={loader}
                >
                  {" "}
                  {getNextText(activeStep)}
                </Button>
              </div>
            </div>
          )}
          <br />
        </div>
      )}
    </>
  );
};

export default DhcSurvey;
