import {
  Button,
  FormField,
  Grid,
  Icons,
  Option,
  Select,
  Tag,
  Text,
  TextArea,
  TextInput,
  Checkbox,
} from "@sede-x/shell-ds-react-framework";
import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api, details } from "../../api";
import omit from "lodash.omit";
import { Context, actions } from "../../context";
import SnackBar from "../SnackBars";
import "./NewProject.css";
import {
  Add,
  Cross,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { GET_ALL_EMAIL_IDS } from "../../api/APIdetails";
import { checkArr } from "./DataConfig";
import axios from "axios";

const NewProject = ({
  loadProjects,
  listOfAreas,
  listOfStages,
  listOfTeamSizes,
  edtStages,
}) => {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const { project = {} } = state;
  const { BASE_URL, POST_PROJECTDETAILS_URL, GET_PROJECTOTHERDETAILS_URL } =
    details;
  const { banner, listOfComplexity = [], listOfImpacts = [] } = state;
  const formData = state.formData;
  const loginUser = localStorage.getItem("loginUser")
    ? JSON.parse(localStorage.getItem("loginUser"))
    : {};

  const [errors, setErrors] = useState({});
  const [errorMsg, setErrMsg] = useState("");
  const [detErrorMsg, setDetErrMsg] = useState("");

  const [selectedListOfImpact, setSelectedListOfImpact] = useState([]);
  const [selectedStagesList, setSelectedStagesList] = useState(listOfStages);

  const [FOFlag, setFOFlag] = useState(true);
  const [edtFlag, setEdtFlag] = useState(true);

  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState("");

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  // const [checkBoxVal, setCheckBoxVal] = useState();

  const [loader, setLoader] = useState(false);
  const [btnDis, setBtnDis] = useState(false);

  const [formHasData, setFormHasData] = useState(false);

  const [form, setFormData] = useState({
    Areas: "Data Science, Statistics & A.I",
    ProjectStage: null,
    TeamSize: null,
    FunnelProjectID: null,
    Name: null,
    Email: loginUser.EmailID + "",
    Description: null,
    ImpactID: null,
    ComplexityID: null,
    MCDSOrg: null,
  });

  const [allEmailIds, setAllEmailIds] = useState([]);

  const handleClick = async () => {
    const isValid = validate(errors);
    if (isValid === true) {
      await new Promise((resolve) => {
        setFormHasData(false);
        resolve();
      });
      postProjectDetails();
      setLoader(!loader);
      setBtnDis(!btnDis);
    } else {
      setErrors({ ...isValid });
      setLoader(false);
      setBtnDis(false);
    }
  };

  const validate = (errors) => {
    const {
      Name,
      ImpactID,
      ComplexityID,
      Areas,
      TeamSize,
      FunnelProjectID,
      Description,
      MCDSOrg,
    } = form;
    let validForm = {
      Name,
      ImpactID,
      ComplexityID,
      TeamSize,
      FunnelProjectID,
      Description,
      MCDSOrg,
    };

    // if (Areas === "Financial Operations") {
    //   validForm = omit(validForm, ["ImpactID", "ComplexityID", "TeamSize"]);
    // }
    // if (Areas === "Emerging Digital Technologies") {
    //   validForm = omit(validForm, ["TeamSize", "ImpactID"]);
    // }

    let isError = false,
      inValid = false;
    console.log("aAAaSss", form);
    Object.keys(validForm).forEach((key) => {
      if (
        form[key] === null ||
        form[key] === undefined ||
        form[key].length <= 0
      ) {
        // console.log(form[key], key);
        isError = true;
        errors[key] = `Please Enter ${key}`;
      } else if (key === "Description") {
        if (validateDescription(form.Description)) {
          isError = true;
          errors[key] = `Please Enter Valid Characters in ${key}`;
        }
      } else if (errors[key] && !inValid) {
        // console.log('here', key, errors[key]);
        inValid = errors[key].value !== "" ? true : false;
      }
    });
    if (isError) {
      return errors;
    } else if (inValid) {
      return errors;
    } else {
      return true;
    }
  };

  const usePrevious = (value = {}) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current | {};
  };

  const prevlistOfImpacts = usePrevious(listOfImpacts);

  useEffect(() => {
    if (formHasData) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formHasData]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "You have unsaved changes, are you sure you want to leave?";
  };

  useEffect(() => {
    console.log(formHasData);

    if (formHasData) {
      const unblock = history.block((location, action) => {
        if (action === "PUSH") {
          return window.confirm(
            "You have unsaved changes, are you sure you want to leave this page?"
          );
        }
        return true;
      });
      return () => unblock();
    }
  }, [history, formHasData]);

  useEffect(() => {
    dispatch({
      type: actions.SET_Projects,
      payload: {
        ...project,
        isNewProject: true,
      },
    });

    if (
      (listOfImpacts.length === 0 && listOfComplexity.length === 0) ||
      listOfImpacts.length !== prevlistOfImpacts.length
    ) {
      loadDetails();
      loadEmailIds();
    }
    return () => {
      setBanner(false, "", "");
    };
  }, []);

  const loadEmailIds = async () => {
    try {
      // const header = {
      //   Authorization: `Bearer ${
      //     JSON.parse(localStorage.getItem("tokenDetails")).access_token
      //   }`,
      // };
      let url = `${BASE_URL}${GET_ALL_EMAIL_IDS}`;
      setErrMsg("");
      let response = await axios.get(url); //load all email ids
      // console.log(response);
      let { data: { recordsets = [] } = {}, error } = response;
      // console.log(recordsets);
      setAllEmailIds(recordsets[0]);

      // if (error) {
      //   setBanner(error);
      //   // console.log(error.toJSON());
      // }
    } catch (error) {
      // console.log(error.toJSON.message());
      // console.log(err.response.status);
      // console.log(err.response.headers);
      console.log(`Email-`, error.message);
      // setErrMsg(error.message);
      // if (!error.response) {
      //   history.push("/errorPage");
      // } else {
      // console.log(error.response.status);
      const errorCode = error.response ? error.response.status : 500;
      history.push("/errorPage", { error, errorCode });
      // }
      // console.log(error.toJSON());
    }
  };

  const loadDetails = async () => {
    try {
      let url = `${BASE_URL}${GET_PROJECTOTHERDETAILS_URL}`;
      let response = await axios.get(url);

      let { data: { recordsets = [] } = {}, error } = response;
      let [listOfImpacts, listOfComplexity] = recordsets;
      const selectedData = listOfImpacts.filter((t) => t.Areas === form.Areas);
      setSelectedListOfImpact(selectedData);
      error
        ? setBanner(error)
        : dispatch({
            type: actions.SET_ProjectDetails,
            payload: { listOfImpacts, listOfComplexity },
          });
    } catch (error) {
      console.log(`Project Other Details-`, error.message);
      setDetErrMsg(error.message);
      const errorCode = error.response ? error.response.status : 500;
      history.push("/errorPage", { error, errorCode });
      // console.log(error.response);
    }
  };

  // Create new project
  const postProjectDetails = async () => {
    try {
      const {
        ComplexityID,
        Description,
        ImpactID,
        Name,
        Areas,
        ProjectStage,
        TeamSize,
        FunnelProjectID,
      } = form;
      let Email = loginUser.EmailID;
      if (
        typeof value !== "undefined" &&
        value !== null &&
        Array.isArray(value)
      ) {
        const valueString = value.join(",");
        Email = Email + "," + valueString;
      }
      let McdsCheck = "";
      if (
        typeof selectedCheckboxes !== "undefined" &&
        selectedCheckboxes !== null &&
        Array.isArray(selectedCheckboxes)
      ) {
        const valueString = selectedCheckboxes.join(",");
        McdsCheck = valueString;
      }
      console.log("Mcds- " + McdsCheck);
      const params = {
        ComplexityID,
        Description,
        Email,
        ImpactID,
        Name,
        Areas,
        ProjectStage,
        TeamSize,
        FunnelProjectID,
        McdsCheck,
        userName: `${loginUser.FirstName} ${loginUser.LastName}`,
      };

      dispatch({
        type: actions.SET_FORM_DATA,
        payload: params,
      });
      dispatch({
        type: actions.SET_SelectedFlag,
        payload: "newProject",
      });
      let url = `${BASE_URL}${POST_PROJECTDETAILS_URL}`;
      // let response = await axios.post(url, params);
      // let { data: { recordset: recordSet = [] } = {}, error } = response;
      // let ProjectID = recordSet[0].ProjectID;
      // console.log("Project ID- ", ProjectID);
      // console.log("Record Set Post- ", recordSet);
      // console.log("Response- ", response);
      // if (error) {
      //   console.log("Error in Create Project");
      //   setBanner(true, "error", error);
      // } else {
      //   setBanner(true, "success", "Project Created Successfully");
      loadProjects(true);
      setFormHasData(false);
      if (Areas === "Financial Operations") {
        history.push("/riskAssessment");
        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 5,
          },
        });
      } else if (Areas === "Emerging Digital Technologies") {
        history.push("/edt");
        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 6,
          },
        });
      } else {
        history.push("/survey");

        dispatch({
          type: actions.SET_selectedMenuIndex,
          payload: {
            index: 4,
          },
        });
      }
      // }
    } catch (error) {
      console.log(`Project Not Created-`, error.message);
      const errorCode = error.response ? error.response.status : 500;
      history.push("/errorPage", { error, errorCode });
      // setDetErrMsg(error.message);
      // console.log(error.response);
    }
  };

  useEffect(() => {
    if (formData) {
      console.log("Form Data", formData);
    }
  }, [state.formData]);

  const setBanner = (show, type = "error", message) => {
    dispatch({
      type: actions.SET_Banner,
      payload: {
        show,
        type,
        message,
      },
    });
  };

  //not in use for area
  const handleChange = (e) => {
    setFormHasData(true);
    let { name, value } = e.target;
    if (name === "Areas") {
      const selectedData = listOfImpacts.filter((t) => t.Areas === value);
      // if (
      //   value === "Financial Operations" ||
      //   value === "Emerging Digital Technologies"
      // )
      if (value === "Financial Operations") {
        setFOFlag(false);
      } else {
        setFOFlag(true);
      }
      setSelectedListOfImpact(selectedData);
    }
    if (name === "Description") {
      if (validateDescription(value)) {
        setErrors({
          ...errors,
          Description: "Description contains invalid characters",
        });
        console.log("Invalid Description");
      } else {
        setErrors({ ...errors, Description: "" });
      }
    }
    name === "Areas" &&
      setFormData({
        ...form,
        ["ImpactID"]: null,
        ["ComplexityID"]: null,
      });
    setFormData({
      ...form,
      [name]: value,
    });
  };

  const validateDescription = (value) => {
    const invalidChars = /['"\n]/;
    return invalidChars.test(value);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleEmailSelect = (value) => {
    // setFormHasData(true);
    if (selectedEmails.length < 2) {
      setSelectedEmails(value);
      setValue(value);
    }
  };

  const filteredOptions = allEmailIds.filter((option) =>
    option.EmailID.toString().toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (itemName) => {
    setFormHasData(true);
    // Toggle the selection status for the clicked checkbox
    const updatedSelection = selectedCheckboxes.includes(itemName)
      ? selectedCheckboxes.filter((item) => item !== itemName)
      : [...selectedCheckboxes, itemName];
    setErrors({ ...errors, MCDSOrg: "" });
    setFormData({
      ...form,
      ["MCDSOrg"]: updatedSelection,
    });
    // Update the state with the new selection
    setSelectedCheckboxes(updatedSelection);
    // console.log(selectedCheckboxes);
    // Remove the error when at least one checkbox is selected
  };
  // console.log(selectedCheckboxes);
  // console.log("Errors:", errors);
  return (
    <div className="form-container">
      {banner.show && (
        <SnackBar banner={banner} onClose={() => setBanner(false, "", "")} />
      )}
      <Grid container columns="53% auto" columnGap="20px">
        <Grid children>
          <FormField
            id="projectName"
            label="Project Name"
            description="Use the same name as the Salesforce entry if available"
            size="large"
            mandatory
            autoComplete="new-password" // disable autocomplete and autofill
            onChange={(e) => handleChange(e)}
            bottomLeftHelper={{
              content: errors.Name ? errors.Name : "",
              sentiment: "negative",
            }}
            onBlur={(e) => {
              if (e.target.value !== "") {
                setErrors({ ...errors, Name: "" });
              }
            }}
          >
            <TextInput name="Name" autoComplete="new-password" />
          </FormField>
        </Grid>
        <Grid children>
          <FormField
            id="FunnelProjectID"
            label="Project Opportunity ID"
            description="Project ID should be from Salesforce, ServiceNow and/or DevOps"
            size="large"
            mandatory
            bottomLeftHelper={{
              content: errors.FunnelProjectID
                ? "Please Select Project Opportunity ID"
                : "",
              sentiment: "negative",
            }}
            onBlur={(e) => {
              if (e.target.value !== "") {
                setErrors({ ...errors, FunnelProjectID: "" });
              }
            }}
            placeholder="If multiple, separate by semi-colon"
            onChange={(e) => handleChange(e)}
          >
            <TextInput name="FunnelProjectID" autoComplete="new-password" />
          </FormField>
        </Grid>
      </Grid>
      <br />
      <Grid container columns="53% auto" columnGap="20px">
        <Grid children>
          <FormField
            id="desc"
            label="Project Description"
            description="Add a brief project description"
            size="large"
            onChange={(e) => handleChange(e)}
            mandatory
            bottomLeftHelper={{
              content: errors.Description ? errors.Description : "",
              sentiment: "negative",
            }}
            onBlur={(e) => {
              if (e.target.value !== "") {
                setErrors({ ...errors, Description: "" });
              }
            }}
          >
            <TextArea
              resize="none"
              name="Description"
              autoComplete="new-password"
              placeholder="Max. 100 words. No special characters (e.g. &quot;&quot;, '', New Lines)"
              // size="large"
              rows={3}
            />
          </FormField>
        </Grid>
        <Grid children>
          <FormField
            id="MCDSOrg"
            label="MCDS Organisation(s) involved"
            description="Which organisation is working on the project delivery? Multiple selection is possible"
            size="large"
            mandatory
            bottomLeftHelper={{
              content: errors.MCDSOrg
                ? "Please Select one of the Following"
                : "",
              sentiment: "negative",
            }}
          >
            {checkArr().map((item, index) => (
              <div key={index} style={{ paddingBottom: "1px" }}>
                <Checkbox
                  checked={selectedCheckboxes.includes(item.name)}
                  onChange={() => handleCheckboxChange(item.name)}
                  name={item.name}
                  size="small"
                  label={item.label}
                />
              </div>
            ))}
          </FormField>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        columns={2}
        columnGap="20px"
        className="lowerHalf-Container"
      >
        <Grid children>
          <FormField
            id="projectArea"
            label="Domain"
            description="Which MCDS domain does this project align most with? Single choice only"
            size="large"
            mandatory
          >
            <Select
              size="large"
              defaultValue={form.Areas}
              name="Areas"
              allowClear={false}
              onChange={(value) => {
                const selectedData = listOfImpacts.filter(
                  (t) => t.Areas === value
                );
                setSelectedStagesList(listOfStages);
                setEdtFlag(true);
                if (value === "Financial Operations") {
                  setFOFlag(false);
                } else {
                  setFOFlag(true);
                }
                setSelectedListOfImpact(selectedData);
                setFormData({
                  ...form,
                  ["ImpactID"]: null,
                  ["ComplexityID"]: null,
                  ["ProjectStage"]: null,
                  Areas: value,
                });
              }}
            >
              {listOfAreas.map((item, i) => (
                <Option key={i} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Grid>
        <Grid children>
          <FormField
            id="ProjectStage"
            label="Project Stage"
            size="large"
            // bottomLeftHelper={{
            //   content: errors.ProjectStage ? "Please Select Project Stage" : "",
            //   sentiment: "negative",
            // }}
          >
            <Select
              size="large"
              placeholder="Select Stage"
              name="ProjectStage"
              allowClear={false}
              value={form.ProjectStage}
              onBlur={(value) => {
                if (value !== "") {
                  setErrors({ ...errors, ProjectStage: "" });
                }
              }}
              onChange={(value) => {
                setFormData({
                  ...form,
                  ProjectStage: value,
                });
              }}
            >
              {selectedStagesList.map((item, i) => (
                <Option key={i} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Grid>
      </Grid>
      <br />
      {FOFlag && (
        <>
          <Grid
            container
            columns={edtFlag ? 2 : 1}
            columnGap="20px"
            className="lowerHalf-Container"
          >
            {detErrorMsg.length < 1 ? (
              <Grid children>
                <FormField
                  id="codecomplexity"
                  label="Code Complexity"
                  size="large"
                  mandatory
                  bottomLeftHelper={{
                    content: errors.ComplexityID
                      ? "Please Select Project Complexity"
                      : "",
                    sentiment: "negative",
                  }}
                >
                  <Select
                    size="large"
                    allowClear={false}
                    name="ComplexityID"
                    placeholder="Select Code Complexity"
                    onBlur={(value) => {
                      if (value !== "") {
                        setErrors({ ...errors, ComplexityID: "" });
                      }
                    }}
                    onChange={(value) => {
                      setFormData({
                        ...form,
                        ComplexityID: value,
                      });
                    }}
                  >
                    {listOfComplexity.map((item, i) => {
                      let { ComplexityLevel, ComplexityID, Desciption } = item;
                      return (
                        <Option key={i} value={ComplexityID}>
                          <b>{ComplexityLevel}</b>
                          {"\u00A0"} {`(${Desciption})`}
                        </Option>
                      );
                    })}
                  </Select>
                </FormField>
              </Grid>
            ) : (
              <h2 style={{ color: "red", textAlign: "center" }}>
                {detErrorMsg}
              </h2>
            )}
            {edtFlag && (
              <Grid children>
                <FormField
                  id="TeamSize"
                  description="Members of the core technical team"
                  label="Team Size"
                  size="large"
                  mandatory
                  bottomLeftHelper={{
                    content: errors.TeamSize ? "Please Select Team Size" : "",
                    sentiment: "negative",
                  }}
                >
                  <Select
                    size="large"
                    allowClear={false}
                    placeholder="Select Team Size"
                    name="TeamSize"
                    onBlur={(value) => {
                      if (value !== "") {
                        setErrors({ ...errors, TeamSize: "" });
                      }
                    }}
                    onChange={(value) => {
                      setFormData({
                        ...form,
                        TeamSize: value,
                      });
                    }}
                  >
                    {listOfTeamSizes.map((item, i) => {
                      return (
                        <Option key={i} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </FormField>
              </Grid>
            )}
          </Grid>
          <br />
          {edtFlag && (
            <Grid container columns={1} columnGap="20px">
              {detErrorMsg.length < 1 ? (
                <Grid children>
                  <FormField
                    id="projectImpact"
                    label="Project Impact"
                    size="large"
                    mandatory
                    bottomLeftHelper={{
                      content: errors.ImpactID
                        ? "Please Select Project Impact"
                        : "",
                      sentiment: "negative",
                    }}
                  >
                    <Select
                      size="large"
                      allowClear={false}
                      name="ImpactID"
                      placeholder="Select Impact"
                      onBlur={(value) => {
                        if (value !== "") {
                          setErrors({ ...errors, ImpactID: "" });
                        }
                      }}
                      onChange={(value) => {
                        setFormData({
                          ...form,
                          ImpactID: value,
                        });
                      }}
                    >
                      {selectedListOfImpact.map((item, i) => {
                        let { ImpactLevel, ImpactID, Description } = item;
                        return (
                          <Option key={i} value={ImpactID}>
                            <b>{ImpactLevel}</b> {"\u00A0"}
                            {`(${Description})`}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormField>
                </Grid>
              ) : (
                <h2 style={{ color: "red", textAlign: "center" }}>
                  {detErrorMsg}
                </h2>
              )}
            </Grid>
          )}
        </>
      )}
      <br />
      <Grid container columns={1} columnGap="20px">
        {errorMsg.length < 1 ? (
          <Grid children>
            <div className="point-of-contact">
              <Text className="contact-label">Point of Contact</Text>
              <div className="contact-values">
                <Tag variant="outlined">{loginUser.EmailID}</Tag>
                <Select
                  size="medium"
                  mode="tags"
                  showSearch
                  allowClear
                  onChange={handleEmailSelect}
                  onSearch={handleSearch}
                  // value={searchTerm ? selectedEmails : undefined}
                  value={selectedEmails}
                  placeholder="Max. 2 additional Shell email addresses"
                  disabled={selectedEmails.length === 2}
                >
                  {/* Render options only for the filtered options */}
                  {searchTerm &&
                    filteredOptions.map((option) => (
                      <Option key={option.EmailID} value={option.EmailID}>
                        {option.EmailID}
                        {/* {option} */}
                      </Option>
                    ))}
                </Select>
                {selectedEmails.length === 2 && (
                  <Button
                    iconOnly
                    icon={<Cross />} // Replace with your Cross icon
                    variant="transparent"
                    onClick={() => {
                      setSelectedEmails([]);
                      setSearchTerm("");
                      setValue(undefined);
                      // setSelectedEmails(!emailBtnDis);
                    }}
                  ></Button>
                )}
              </div>
            </div>
          </Grid>
        ) : (
          <h2 style={{ color: "red", textAlign: "center" }}> {errorMsg} </h2>
        )}
      </Grid>
      <br />
      <Grid container columns={1} columnGap="20px">
        <Grid children>
          <div style={{ textAlign: "center" }}>
            <Button
              size="medium"
              variant="filled"
              sentiment="default"
              onClick={() => {
                handleClick();
              }}
              loading={loader}
              disabled={btnDis}
            >
              CREATE PROJECT
            </Button>
          </div>
        </Grid>
      </Grid>
      <br />
      <br />
      <div className="footer">
        <Text>
          The DHC is a ‘self-help’ tool to allow you to reflect, as the project
          team, on how the project is progressing. The surveys, which include
          content assurance and software engineering questions are loosely based
          on the MCDS Technical Standards (more information available in the FAQ
          section).
          <p />
          We suggest that you, as a project team, also complete the MCDS RAM
          (Risk Assessment Matrix) which can be found separately following this{" "}
          <a
            href="https://forms.office.com/Pages/ResponsePage.aspx?id=qJYe29qjKkSTCyNcrCTNXNMc3Kyc_-JPkziUI27_ecJUNlcyV0dXSTBRRjdBWUdZUkZHVDFFVk5CWi4u"
            target="_blank"
          >
            form
          </a>{" "}
          or on the MCDS website. This Risk Matrix will also determine whether
          your project requires MCDS Technical Assurance. Take a look at the
          MCDS Website for more information.
        </Text>
      </div>
      <br />
    </div>
  );
};

export default NewProject;
